import React, { useState, useEffect } from "react";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { CopyButton } from "components/common/CopyButton";
import { DeleteButton } from "components/common/DeleteButton";
import { RefreshButton } from "components/common/RefreshButton";
import Icon from "components/common/Icon";
import { Refresh } from "@styled-icons/boxicons-regular/Refresh";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import { ChannelConnectionStatus } from "@hellocontento/contento-common";
import {
  EmptyContainer,
  ContentContainer
} from "components/common/AdminHeader";
import {
  Table,
  TableRow,
  TableCell,
  TableHeader
} from "components/common/Table";
import Pagination from "components/operations/Pagination";

interface ChannelConnection {
  channelId: string;
  connectionDetails: {
    iv: string;
    authTag: string;
    encryptedData: string;
  };
  connectionType: string;
  createdAt: string;
  deletedAt: string | null;
  disabledUntil: string;
  id: string;
  lastErrorAt: string;
  lastErrorType: string;
  recentErrorCount: number;
  updatedAt: string;
}

interface Channel {
  id: string;
  accountId: string;
  username: string;
  name: string;
  service: string;
  lastScheduledPostTime?: string;
  disabledUntil?: string;
  details?: string;
  accountName: string;
  accountStatus: string;
  accountLogo: string;
  connectionStatus: ChannelConnectionStatus;
  channelConnections: ChannelConnection[];
}

interface MappedChannel extends Channel {
  status: string;
}

interface Account {
  id: string;
  companyName: string;
  status: string;
  logo: string;
  channels: Channel[];
}

interface ChannelIssuesTableProps {
  selectedTypeFilters: string[];
  searchTerm: string;
}

const ChannelIssuesTable: React.FC<ChannelIssuesTableProps> = ({
  selectedTypeFilters,
  searchTerm
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allData, setAllData] = useState<MappedChannel[]>([]);
  const itemsPerPage = 10;

  useEffect(() => {
    callApi({
      method: "get",
      url: `/accounts`
    })
      .then(response => {
        console.log("API Response:", response);
        const accounts: Account[] = response.rows;

        const channels: MappedChannel[] = accounts.flatMap(account =>
          account.channels
            .filter(channel => channel.connectionStatus === "needsReconnection")
            .map(channel => {
              const disabledUntil =
                channel.channelConnections?.[0]?.disabledUntil ?? undefined;
              const lastErrorType =
                channel.channelConnections?.[0]?.lastErrorType ?? "No error";

              let status = "Active";
              if (channel.connectionStatus === "needsReconnection") {
                status = "Needs Reconnection";
              } else if (channel.connectionStatus === "needsVerification") {
                status = "Needs Verification";
              } else if (lastErrorType !== "No error") {
                status = `Error: ${lastErrorType}`;
              }

              return {
                ...channel,
                accountName: account.companyName,
                accountStatus: account.status,
                accountLogo: account.logo,
                lastScheduledPostTime: channel.lastScheduledPostTime,
                disabledUntil,
                details: lastErrorType,
                status
              };
            })
        );

        console.log("Mapped Channels:", channels);
        setAllData(channels);
      })
      .catch(error => {
        toastr.error("Error", error.message);
      });
  }, []);

  const filterData = () => {
    let filtered = allData;

    if (!selectedTypeFilters.includes("All")) {
      filtered = filtered.filter(channel =>
        selectedTypeFilters.includes(channel.service)
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(channel =>
        channel.accountName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filtered;
  };

  const filteredData = filterData();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const deleteChannel = (channel: MappedChannel) => {
    const confirmed = window.confirm("Sure you want to delete this channel?");
    if (confirmed) {
      callApi({
        method: "delete",
        url: `/accounts/${channel.accountId}/channels/${channel.id}`
      })
        .then(() => {
          toastr.success("Delete", "Deleted channel");
          setAllData(allData.filter(c => c.id !== channel.id));
        })
        .catch(error => {
          toastr.error("Error", error.message);
        });
    }
  };

  const copyLink = (channel: MappedChannel) => {
    const channelId = channel.id;
    navigator.clipboard
      .writeText(channelId)
      .then(() => {
        toastr.success(
          "Channel ID Copied",
          "The channel ID has been copied to your clipboard"
        );
      })
      .catch(err => {
        toastr.error("Error", "Failed to copy the ID");
      });
  };

  const refreshChannel = () => {
    toastr.info("Coming soon", "This feature is coming soon.");
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTypeFilters, searchTerm]);

  return (
    <>
      <EmptyContainer>
        <ContentContainer>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Account</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader>Channel</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Details</TableHeader>
                <TableHeader>Last Active</TableHeader>
                <TableHeader>Disabled Until</TableHeader>
                <TableHeader>Actions</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {currentItems.map(channel => (
                <TableRow key={channel.id}>
                  <TableCell>
                    {channel.accountLogo && (
                      <img
                        src={channel.accountLogo}
                        alt="Logo"
                        style={{ width: "20px", marginRight: "8px" }}
                      />
                    )}
                    {channel.accountName || "Unknown Account"}
                  </TableCell>
                  <TableCell>
                    {channel.service.charAt(0).toUpperCase() +
                      channel.service.slice(1) || "Unknown Type"}
                  </TableCell>
                  <TableCell>{channel.username || "Unknown Channel"}</TableCell>
                  <TableCell>{channel.status || "Unknown Status"}</TableCell>
                  <TableCell>{channel.details || "No details"}</TableCell>
                  <TableCell>
                    {channel.lastScheduledPostTime
                      ? moment(channel.lastScheduledPostTime).format(
                          "DD/MM/YYYY"
                        )
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {channel.disabledUntil
                      ? moment(channel.disabledUntil).format("DD/MM/YYYY")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <CopyButton onClick={() => copyLink(channel)}>
                      <Icon
                        component={Copy}
                        size={20}
                        style={{ marginRight: "8px" }}
                      />
                      Copy ID
                    </CopyButton>
                    <RefreshButton onClick={refreshChannel}>
                      <Icon
                        component={Refresh}
                        size={20}
                        style={{ marginRight: "8px" }}
                      />
                      Reset
                    </RefreshButton>
                    <DeleteButton onClick={() => deleteChannel(channel)}>
                      <Icon
                        component={Delete}
                        size={20}
                        style={{ marginRight: "8px" }}
                      />
                      Delete
                    </DeleteButton>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </ContentContainer>
      </EmptyContainer>
      <EmptyContainer>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredData.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </EmptyContainer>
    </>
  );
};

export default ChannelIssuesTable;
